import React, { memo, useCallback, useMemo, useRef } from "react"
import styled from "@emotion/styled"
import videojs from "video.js"
import { LivestreamType } from "@core/types/domain/Livestream"
import { VideoJsPlayer } from "~/components/video/videojs/VideoJsPlayer"
import Button from "~/components/button/Button"
import { timePickerInputFormatCss } from "~/screens/app/detection/stream/videorange/TimePicker"
import { css } from "@emotion/react"
import {
  RecordingCutControls,
  useRecordingCutControlState,
} from "~/screens/app/detection/stream/videorange/RecordingCutControls"
import { useHasPermission } from "~/store/login/permissionSelectors"
import { UserPermission } from "@core/types/domain/user/UserPermission"
import { StoredTaskInfo } from "@core/service/detection/fetchStoredTaskInfo"
import {
  UseVideoRanges,
  useVideoRangeState,
} from "~/screens/app/detection/stream/videorange/range/useVideoRanges"
import { RangeSelector } from "~/screens/app/detection/stream/videorange/range/RangeSelector"
import { roundN } from "~/utils/js/mathUtils"
import { StreamSaveSection } from "~/screens/app/detection/stream/videorange/select/StreamSaveSection"

export interface TimeRangeInfo {
  from: number
  to: number
}

interface VideoRangeSelectorProps {
  task: StoredTaskInfo
  stream: LivestreamType
  onSelect: UseVideoRanges["setRanges"]
  initialRanges: UseVideoRanges["ranges"]
}

export const SectionSelectorView = memo(
  ({ initialRanges, onSelect, stream, task }: VideoRangeSelectorProps) => {
    const canCut = useHasPermission(UserPermission.canCutRecording)
    const $player = useRef<videojs.Player>()
    const onReady = useCallback((player) => {
      $player.current = player
    }, [])
    const onJumpToPos = useCallback((second: number) => {
      $player.current.currentTime(second)
    }, [])

    const [cutControlState, setCutControlState] = useRecordingCutControlState()
    const videoUrl = useMemo(() => {
      if (!canCut) return task.previewVideoUrl
      if (cutControlState.showOriginalPreview) return task.originalPreviewUrl
      return task.previewVideoUrl
    }, [task, canCut, cutControlState])
    const ranges = useVideoRangeState(initialRanges)
    const isValid = ranges.ranges.length
    const onSubmit = useCallback(() => {
      onSelect(
        ranges.ranges.map((r) => ({
          from: r.from,
          to: r.to,
        }))
      )
    }, [ranges])
    return (
      <Container>
        <VideoContainer>
          <VideoJsPlayer onReady={onReady} src={videoUrl} fluid />
        </VideoContainer>
        <ControlsCol>
          <RangeSelector
            {...ranges}
            getCurrentPlayerTime={() =>
              roundN($player.current.currentTime(), 1)
            }
          />
          <div>
            <Button
              variant={"is-info"}
              disabled={!isValid}
              onClick={onSubmit}
              css={css`
                margin-right: 1em;
              `}
            >
              Get stats by selection
            </Button>
          </div>
          <SaveRow>
            <StreamSaveSection stream={stream} {...ranges} />
          </SaveRow>
          {canCut ? (
            <RecordingCutControls
              task={task}
              stream={stream}
              state={cutControlState}
              setState={setCutControlState}
              ranges={ranges.ranges}
            />
          ) : null}
        </ControlsCol>
      </Container>
    )
  }
)

const SaveRow = styled.div`
  display: flex;
  & > * {
    margin-right: 1em;
  }
`

const ControlsCol = styled.div`
  & > div {
    margin-bottom: 1rem;
  }
`

const Container = styled.div`
  display: flex;
  margin: -12px;
  & > div {
    margin: 12px;
  }
  ${timePickerInputFormatCss};
`

const VideoContainer = styled.div`
  background-color: black;
  width: 640px;
`
