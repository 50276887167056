import { useState } from "react"

export enum SectionStatsFilterType {
  "size" = "size",
  "place" = "place",
}

export interface SectionStatsFilterState {
  type: SectionStatsFilterType
}

const initial: SectionStatsFilterState = {
  type: SectionStatsFilterType.size, // TODO
}

export function useSectionStatsFilter() {
  return useState(initial)
}
