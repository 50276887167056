import React, { Dispatch, SetStateAction } from "react"
import styled from "@emotion/styled"
import { Brand } from "../../demo/data/brands"

interface BrandSelectionProps {
  brands: Brand[]
  selectedBrand?: Brand
  setSelectedBrand: Dispatch<SetStateAction<Brand>>
}

function BrandSelection({
  selectedBrand,
  setSelectedBrand,
  brands,
}: BrandSelectionProps) {
  return (
    <LogoSelection className={selectedBrand ? "has-selection" : undefined}>
      {brands.map((brand) => {
        const selected = brand === selectedBrand
        return (
          <Item
            key={brand.name}
            data-tooltip={brand.label}
            data-balloon-pos={"down"}
            onClick={() =>
              setSelectedBrand((b) => (b !== brand ? brand : undefined))
            }
            className={selected ? "is-selected" : undefined}
          >
            <img src={brand.img} alt="" />
          </Item>
        )
      })}
    </LogoSelection>
  )
}

const Item = styled.div`
  cursor: pointer;
  margin-right: 24px;
  margin-top: 12px;
  &:hover {
    opacity: 0.7;
    img {
      filter: contrast(1.2);
    }
  }
`

const LogoSelection = styled.div`
  display: flex;
  flex-wrap: wrap;

  img {
    max-width: 64px;
  }

  &.has-selection {
    img {
      opacity: 0.3;
    }
    .is-selected img {
      opacity: 1;
    }
  }
`

export default BrandSelection
