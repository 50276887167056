import React from "react"
import { StateUpdater } from "@core/types/utils/utilTypes"
import { DetectionResult } from "@core/types/detection/DetectionTypes"
import styled from "@emotion/styled"
import TextFilter, { Option } from "~/components/filter/TextFilter"
import {
  DetectionFilter,
  DetectionFilterTarget,
} from "~/screens/app/detection/stats/components/filter/DetectionFilter"
import { LocationFilter } from "~/screens/app/detection/stats/components/filter/LocationFilter"

interface DetectionFilterProps {
  filter: DetectionFilter
  setFilter: StateUpdater<DetectionFilter>
  rawStats: DetectionResult[]
}

export function DetectionFilterView({
  filter,
  setFilter,
  rawStats,
}: DetectionFilterProps) {
  return (
    <Container>
      <LocationFilter
        filter={filter}
        updateFilter={setFilter}
        rawStats={rawStats}
      />
      <FilterContainer>
        <FilterLabel>Filter by target</FilterLabel>
        <TextFilter
          options={targetOptions}
          value={filter.target}
          onChange={(value) => {
            setFilter((f) => ({
              ...f,
              target: value as DetectionFilterTarget,
            }))
          }}
        />
      </FilterContainer>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 12px;
  text-align: right;
`

export const FilterContainer = styled.div`
  margin-left: 32px;
`

export const FilterLabel = styled.span`
  margin-right: 12px;
  opacity: 0.8;
`

const targetOptionConfig: { [key in DetectionFilterTarget]: Option } = {
  [DetectionFilterTarget.all]: {
    value: "all",
    label: "all",
  },
  [DetectionFilterTarget.person]: {
    value: "person",
    label: "athletes",
  },
  [DetectionFilterTarget.other]: {
    value: "other",
    label: "other",
  },
}
const targetOptions = Object.values(targetOptionConfig).filter(
  (o) => o.value !== DetectionFilterTarget.other
)
