import { useMemo } from "react"
import {
  BrandStats,
  BrandStatsBySize,
  DetectionResult,
  VideoBrandStats,
} from "@core/types/detection/DetectionTypes"
import { mergeObjects } from "~/utils/js/transformUtils"
import { sum } from "~/utils/jsUtils"
import {
  DetectionFilter,
  DetectionFilterTarget,
} from "~/screens/app/detection/stats/components/filter/DetectionFilter"
import { mapObject } from "~/utils/js/operationUtils"

export type DetectionSummary = ReturnType<typeof useDetectionSummary>

export function useDetectionSummary(
  results: DetectionResult[],
  filter?: DetectionFilter
) {
  return useMemo(() => {
    const totalLength = results && sum(results, (r) => r.length || 0)
    const totalViews = results && sum(results, (r) => r.views || 0)
    const prepared = prepareData(results, filter)
    const summed: VideoBrandStats =
      results && filterToShort(accumulateResults(prepared))
    return {
      summed,
      totalLength,
      totalViews,
    }
  }, [results, filter])
}

function filterToShort(summed: VideoBrandStats) {
  // remove brands that have a too few duration
  return (
    summed &&
    Object.entries(summed).reduce((result, [brand, stats]) => {
      const totalDuration = sum(Object.values(stats), (item) => item.duration)
      if (totalDuration > 2) {
        result[brand] = stats
      }
      return result
    }, {})
  )
}

function accumulateResults(
  results: DetectionResult[],
  includeAll = true
): VideoBrandStats {
  return results?.reduce((summed, result, i) => {
    if (!result) return summed

    // single video stats
    return mergeObjects(summed, result.logos, (bySizeTarget, bySize) => {
      const sizeResult = mergeObjects(
        bySizeTarget,
        bySize,
        (target, source) => {
          return {
            name: target.name,
            duration: target.duration + source.duration,
            score: target.score + source.score,
            scale: target.scale + source.scale,
            euroValue: target.euroValue + source.euroValue,
          }
        }
      )
      if (!includeAll) {
        delete sizeResult["all"]
      }
      return sizeResult
    })
  }, {} as VideoBrandStats)
}

function isOldStats(byBrand: BrandStatsBySize) {
  return byBrand["all"]
}

function filterLocation(
  results: DetectionResult[],
  filter: DetectionFilter
): DetectionResult[] {
  const location = filter?.location ? filter.location : "all"
  return results?.map((result) => {
    return {
      ...result,
      logos: mapObject(result.logos, (byBrand) => {
        if (isOldStats(byBrand)) {
          // ignore filter for old format
          return byBrand
        }
        return Object.entries(byBrand).reduce((result, [key, stats]) => {
          const [locationKey, sizeKey] = key.split("_")
          if (location.indexOf(locationKey) !== -1) {
            result[sizeKey] = stats
          }
          return result
        }, {})
      }),
    }
  })
}

function prepareData(results: DetectionResult[], filter?: DetectionFilter) {
  const filteredByLocation = filterLocation(results, filter)
  return mapAllStats(filteredByLocation, (stats) => {
    const factor = getDurationFactor(stats, filter)
    return {
      ...stats,
      duration: factor * stats.duration,
      score: factor * stats.score,
    }
  })
}

function mapAllStats(
  results: DetectionResult[],
  transform: (stats: BrandStats) => BrandStats
) {
  return results?.map((result) => {
    return {
      ...result,
      logos: mapObject(result.logos, (byBrand) =>
        mapObject(byBrand, (bySize) => transform(bySize))
      ),
    }
  })
}

function getDurationFactor(stats: BrandStats, filter: DetectionFilter) {
  if (filter.target === DetectionFilterTarget.person) {
    return stats.onPerson || 0
  } else if (filter.target === DetectionFilterTarget.other) {
    return 1 - (stats.onPerson || 0)
  } else return 1
}
