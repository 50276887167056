import React, { memo } from "react"
import ActiveFilter from "./ActiveFilter"
import { TextFilterType } from "../types/TextFilterType"
import { useFilterContext } from "../context/VideoFilterContext"
import { FilterTagProps } from "./FilterTagProps"
import { FilterType } from "../types/VideoFilterType"

interface TextFilterProps extends FilterTagProps<TextFilterType> {}

function useUpdateFilter<T extends FilterType>(name: string) {
  const { setFilter } = useFilterContext()
  return (updater: (prev: T) => T) => {
    setFilter((current) => ({
      ...current,
      [name]: updater(current[name]),
    }))
  }
}

function TextFilterPopup(props: TextFilterProps) {
  const updateFilter = useUpdateFilter<TextFilterType>(props.field.name)
  return (
    <div>
      <input
        className="input"
        type="text"
        value={props.filter.search}
        onChange={(event) => {
          const { value } = event.target
          updateFilter((prev) => ({
            ...prev,
            search: value,
          }))
        }}
      />
    </div>
  )
}

function TextFilter(props: TextFilterProps) {
  const { filter, field } = props
  return (
    <ActiveFilter
      popup={<TextFilterPopup {...props} />}
      {...props}
      invalid={!filter.search}
    >
      <span>
        {field.label} contains <b>{filter.search || "?"}</b>
      </span>
    </ActiveFilter>
  )
}

export default memo(TextFilter)
