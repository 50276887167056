import { DetectionServer } from "@core/types/detection/DetectionServer"
import slugify from "slugify"

export const excelApiUrl = `${DetectionServer.L1.url}/excel/`

export function slugifyFileName(str: string) {
  return slugify(str, {
    lower: true,
    strict: true,
  })
}
