import React, { memo } from "react"
import styled from "@emotion/styled"
import { darkTextColor } from "../style/styleConst"

interface FooterProps {}

function Footer(props: FooterProps) {
  return null // TODO
  const year = new Date().getFullYear()
  return (
    <Container>
      <div>&copy; {year} onlinecontrol.eu</div>
      <div>v0.0.1</div>
    </Container>
  )
}

const Container = styled.footer`
  color: ${darkTextColor};
  //display: flex;
  //flex-wrap: wrap;
  //justify-content: space-between;
  padding-bottom: 0.5rem;
`

export default memo(Footer)
