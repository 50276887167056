import React, { useMemo } from "react"
import { css } from "@emotion/react"
import styled from "@emotion/styled"
import { faCrop, faStar, faStopwatch } from "@fortawesome/free-solid-svg-icons"
import { sizeByName, sizeByValue } from "@core/types/detection/LogoSize"
import { Brand } from "@core/types/domain/Brand"
import {
  BrandStats,
  DetectionResult,
  VideoBrandStats,
} from "@core/types/detection/DetectionTypes"
import { formatDurationFull } from "~/utils/formatUtils"
import { isCollapsed } from "~/utils/style/styleUtils"
import NumberWidget from "~/screens/app/dashboard/widgets/NumberWidget"
import { useSummedSizeStats } from "~/screens/app/detection/data/sumBrandStats"
import WidgetGrid from "~/components/widget/WidgetGrid"
import { getSizePieChartTexts } from "~/screens/app/detection/summary_legacy/views/SizePieChart"
import { KeyBy } from "~/types/tsUtils"
import { useProject } from "~/store/project/projectHooks"
import { getDetectionSizeExplainText } from "~/screens/app/detection/stats/project/SizeChart"
import { BrandEuroValue } from "~/screens/app/detection/stats/project/brand/BrandEuroValue"
import {
  AttributeChartTexts,
  AttributePieChart,
} from "~/screens/app/detection/summary_legacy/views/AttributePieChart"

export interface ProjectSummaryProps {
  stats: VideoBrandStats
  brand: Brand
  results: DetectionResult[]
  brandsInStats: Brand[]
  totalLength: number
  projectId: number
  type: "social" | "stream"
  attributeChartConfig: AttributeChartTexts | undefined
}

export function ProjectDetectionBrandStats({
  brand,
  stats,
  results,
  totalLength,
  projectId,
  attributeChartConfig,
}: ProjectSummaryProps) {
  const brandStats = useMemo(() => {
    return stats?.[brand.name]
  }, [stats, brand.name])

  const summedBrandStats = useSummedSizeStats(brandStats)
  const dominantSize = useDominantSize(brandStats)

  const brandResults = useMemo(() => {
    if (!results) return undefined
    return results.filter(({ logos }) => logos?.[brand.name])
  }, [results, brand.name])
  const brandVideoCount = brandResults?.length

  const project = useProject(projectId)

  const attributeChartProps = useMemo(() => {
    return attributeChartConfig || getSizePieChartTexts(project)
  }, [project, attributeChartConfig])

  if (!brandStats) return null // should not happen??
  return (
    <>
      <Grid>
        <NumberWidget
          title={"Brand duration"}
          subTitle={"total time the logo was visible"}
          explain={
            "This value indicates the number of seconds the logo was visible."
          }
          icon={faStopwatch}
          value={brandStats["all"]?.duration || summedBrandStats.duration}
          total={totalLength}
          format={formatDurationFull}
          css={css`
            grid-area: A;
          `}
        />
        <NumberWidget
          title={"Brand Score"}
          subTitle={"your individually calculated score"}
          icon={faStar}
          value={summedBrandStats.score}
          css={css`
            grid-area: B;
          `}
        />
        {dominantSize ? (
          <NumberWidget
            title={"Average size"}
            subTitle={"average size"}
            explain={getDetectionSizeExplainText(project)}
            icon={faCrop}
            value={dominantSize.value}
            format={(val) => {
              const size = sizeByValue[val]
              return size?.name || ""
            }}
            countUpProps={{
              duration: 0.0001,
            }}
            suffix={"%"}
            css={css`
              grid-area: C;
            `}
          />
        ) : (
          <div
            css={css`
              grid-area: C;
            `}
          />
        )}
        <BrandEuroValue
          summedStats={summedBrandStats}
          css={css`
            grid-area: D;
          `}
        />
        <AttributePieChart
          brandStats={brandStats}
          brand={brand}
          css={css`
            grid-area: Chart;
          `}
          {...attributeChartProps}
        />
      </Grid>
    </>
  )
}

const Grid = styled(WidgetGrid)`
  grid-template-areas: "A B Chart ." "C D Chart .";
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
  ${isCollapsed} {
    grid-template-areas: "Chart Chart Chart Chart" "A A B B" "C C D D";
  }
`

function useDominantSize(bySize: KeyBy<BrandStats>) {
  return useMemo(() => {
    if (!bySize) return undefined
    const durationBySize = Object.entries(bySize).reduce(
      (result, [size, stats]) => {
        if (size !== "all") {
          result[size] = stats.duration
        }
        return result
      },
      {} as KeyBy<number>
    )
    let max = 0
    let sizeName = null
    Object.entries(durationBySize).forEach(([key, duration]) => {
      if (duration > max) {
        max = duration
        sizeName = key
      }
    })
    return sizeByName[sizeName]
  }, [bySize])
}
