import React from "react"
import styled from "@emotion/styled"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import {
  faImage,
  faLink,
  faNewspaper,
  faQuestion,
  faVideo,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  getMediaType,
  PostMediaType,
} from "@core/types/domain/post/PostMediaType"

interface PostMediaTypeIconProps {
  type: PostMediaType
}

const iconByType: Record<PostMediaType, IconProp> = {
  [PostMediaType.Image]: faImage,
  [PostMediaType.Link]: faLink,
  [PostMediaType.Video]: faVideo,
  [PostMediaType.Text]: faNewspaper,
  [PostMediaType.Unknown]: faQuestion,
}

export function PostMediaTypeIcon(props: PostMediaTypeIconProps) {
  const type = getMediaType(props.type)
  const icon = iconByType[type.id]
  return (
    <Container data-tooltip={type.title}>
      <FontAwesomeIcon icon={icon} fixedWidth />
    </Container>
  )
}

const Container = styled.span`
  display: inline-block;
`
