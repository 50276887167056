import React from "react"
import SelectFilter, { FilterOption } from "../active/select/SelectFilter"
import { FilterTagProps } from "../active/FilterTagProps"
import { IdsFilterType } from "../types/IdsFilterType"
import { allVideoCategories } from "../../../../@core/types/domain/video/VideoCategory"

interface CategoryFilterProps extends FilterTagProps<IdsFilterType> {}

function CategoryFilter(props: CategoryFilterProps) {
  return <SelectFilter {...props} options={options} />
}

const options: FilterOption[] = allVideoCategories.map((platform) => ({
  value: "" + platform.id,
  label: platform.name,
}))

export default CategoryFilter
