import React, { useEffect, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSync } from "@fortawesome/free-solid-svg-icons"
import styled from "@emotion/styled"

interface LoadingIndicatorProps {
  isLoading: boolean
  className?: string
}

export function LoadingIndicator({
  isLoading,
  className,
}: LoadingIndicatorProps) {
  const [visible, setVisible] = useState(isLoading)
  useEffect(() => {
    if (isLoading) {
      setVisible(true)
    } else {
      const hideTask = window.setInterval(() => {
        setVisible(false)
      }, duration)
      return () => window.clearInterval(hideTask)
    }
  }, [isLoading])
  if (!visible) return null
  return (
    <FontAwesomeIcon icon={faSync} spin size={"xs"} className={className} />
  )
}

export const LoadingIcon = styled(LoadingIndicator)`
  opacity: 0.25;
  margin-left: 4px;
`

const duration = 2000
