import React from "react"
import styled from "@emotion/styled"
import { unstyledButtonCss } from "../button/Button"

export interface Option {
  label: string
  value: string
  title?: string
}

export interface TextFilterProps {
  options: Option[]
  value: string
  onChange: (value: string) => void
}

function TextFilter({ options, onChange, value }: TextFilterProps) {
  return (
    <Container>
      {options.map((option) => {
        const active = option.value === value
        return (
          <StatusButton
            key={option.value}
            type={"button"}
            title={option.title}
            onClick={() => {
              if (!active) onChange(option.value)
            }}
            className={active && "active"}
          >
            {option.label}
          </StatusButton>
        )
      })}
    </Container>
  )
}

const Container = styled.div`
  display: inline-block;
`

const StatusButton = styled.button`
  ${unstyledButtonCss};
  &.active {
    text-shadow: 0.25px 0px 0.1px, -0.25px 0px 0.1px;
  }
`

export default TextFilter
