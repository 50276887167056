import React, { useCallback, useMemo, useState } from "react"
import styled from "@emotion/styled"
import { LiveStreamView } from "../../live/LiveStreamView"
import { useBindState } from "../../../../../utils/hooks/useBindState"
import { taskUrlToId } from "../../../../../services/detection/detectionService"
import Notification from "../../../../../components/notification/Notification"
import { Samples } from "../../live/Samples"
import { fetchStopTask } from "../../../../../services/detection/fetchStopTask"
import { DebugDetectionPage } from "../DebugDetectionPage"

export function DebugLiveDetectionScreen() {
  const urlState = useBindState("/root/shared/demo_ath_src.mp4")
  const [url, setUrl] = useState<string>()
  // const { data: info, error } = useTaskInfo("try1")

  const id = useMemo(() => url && taskUrlToId(url).toString(), [url])

  const stopTask = useCallback(() => {
    if (!id) return
    fetchStopTask(id).then(console.log).catch(console.error)
  }, [id])

  return (
    <DebugDetectionPage>
      <Notification type={"info"}>
        The engine can currently only process one live stream at a time.
      </Notification>
      <InputRow>
        <UrlInput className="field has-addons">
          <div className="control">
            <span className="select">
              <Samples onSelect={urlState.setValue} />
            </span>
          </div>
          <div className="control">
            <input
              {...urlState.binders}
              className="input"
              type="text"
              placeholder="URL to video"
            />
          </div>
          {!url && (
            <div className="control">
              <a
                className="button is-info"
                onClick={() => {
                  setUrl(urlState.value)
                }}
              >
                Start
              </a>
            </div>
          )}
          {url && (
            <div className="control">
              <a
                className="button is-danger"
                onClick={() => {
                  stopTask()
                  setUrl(undefined)
                }}
              >
                Stop
              </a>
            </div>
          )}
        </UrlInput>
      </InputRow>
      {id && url && <LiveStreamView id={id} url={url} />}
    </DebugDetectionPage>
  )
}

const InputRow = styled.div`
  margin-bottom: 24px;
`

const UrlInput = styled.div`
  input {
    width: 420px;
    max-width: 100%;
  }
`
