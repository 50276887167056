import { SearchResult } from "./getSearchResults"
import { videoFields } from "../utils/videoFields"
import { TextFilterType } from "../types/TextFilterType"
import { IdsFilterType } from "../types/IdsFilterType"
import { DateFilterType } from "../types/DateFilterType"
import { StaticFilterType } from "../types/StaticFilterType"

const filterFields: SearchResult[] = [
  {
    field: videoFields.publishAt,
    filter: {
      type: "exact",
      date: new Date(),
    } as DateFilterType,
  },
  {
    field: videoFields.title,
    filter: {
      type: "contains",
      search: "",
    } as TextFilterType,
  },
  {
    field: videoFields.channelName,
    filter: {
      type: "contains",
      search: "",
    } as TextFilterType,
  },
  // {
  //   field: videoFields.description,
  //   filter: {
  //     type: "contains",
  //     search: "example",
  //   } as TextFilterType,
  // },
  {
    field: videoFields.status,
    filter: {
      in: [],
    } as IdsFilterType,
  },
  {
    field: videoFields.customStatus,
    filter: {
      in: [],
    } as IdsFilterType,
  },
  {
    field: videoFields.category,
    filter: {
      in: [],
    } as IdsFilterType,
  },
  {
    field: videoFields.platform,
    filter: {
      in: [],
    } as IdsFilterType,
  },
  {
    field: videoFields.eventTag,
    filter: {
      in: [],
    } as IdsFilterType,
  },
  {
    field: videoFields.hasComments,
    groupId: 1,
    filter: {
      value: true,
    } as StaticFilterType,
  },
  {
    field: videoFields.hasNote,
    groupId: 1,
    filter: {
      value: true,
    } as StaticFilterType,
  },
  {
    field: videoFields.isEbuMember,
    groupId: 1,
    filter: {
      value: true,
    } as StaticFilterType,
  },
].map((value: Omit<SearchResult, "key">) => {
  const result = value as SearchResult
  result.key = value.field.name
  return result
})

export default filterFields
