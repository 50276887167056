import React, { useEffect, useMemo } from "react"
import ReactCountUp from "react-countup"
import styled from "@emotion/styled"
import { faSearchDollar } from "@fortawesome/free-solid-svg-icons"
import Widget from "../../../../components/widget/Widget"
import Explain from "../../../../components/bulma/Explain"
import {
  useSelectedProjectIds,
  useSelectedProjects,
} from "~/store/project/projectHooks"
import { widgetNumberCss } from "../widgets/NumberWidget"
import DashboardSection from "./DashboardSection"
import { useFetchState } from "~/utils/hooks/useFetchState"
import { ProjectId } from "@core/types/domain/ProjectType"
import { fetchProjectValues } from "~/services/projectService"
import { ProjectValuesResponse } from "@core/types/api/projectRouteTypes"
import { ProjectFilter, useProjectFilter } from "./filter/ProjectFilter"
import { useUserRole } from "~/store/login/loginHooks"
import { UserRole } from "@core/types/domain/user/UserRole"

interface ProjectValueSectionProps {}

function useProjectValues(projectIds: ProjectId[], filter: ProjectFilter) {
  const [data, dispatch] = useFetchState<ProjectValuesResponse>()
  useEffect(() => {
    if (!projectIds?.length) return
    dispatch.setLoading()
    fetchProjectValues({
      ...filter,
      projectIds: projectIds || filter.projectIds,
    })
      .then(dispatch.onSuccess)
      .catch((reason) => {
        dispatch.onFail(reason)
      })
  }, [projectIds, filter])
  return data
}

function ProjectValueSection(props: ProjectValueSectionProps) {
  const role = useUserRole()
  const projects = useSelectedProjects()
  const projectIds = useSelectedProjectIds()
  const filter = useProjectFilter()
  const { data, isLoading } = useProjectValues(projectIds, filter)

  const hasAnyValueProject = useMemo(() => {
    return !!projects?.find((p) => p.showValue)
  }, [projects])

  const showValue = role === UserRole.SuperAdmin
  const showAdValue = hasAnyValueProject

  if (!data) return null
  const { adValueMax, adValueMin, valueMax, valueMin } = data
  if (!showAdValue && !showAdValue) return null

  return (
    <DashboardSection>
      <Container>
        {showValue && (
          <ValueWidget
            internal
            title={"Value"}
            tooltip={"based on average tv and online/web sales informations"}
            isLoading={isLoading}
            min={valueMin}
            max={valueMax}
          />
        )}
        {showAdValue && (
          <ValueWidget
            internal={false}
            title={"Ad Value"}
            tooltip={
              "Amount needed to reach the number of views, based on a average CPM for a 20 seconds pre-roll-ad"
            }
            isLoading={isLoading}
            min={adValueMin}
            max={adValueMax}
          />
        )}
      </Container>
    </DashboardSection>
  )
}

function ValueWidget({ title, tooltip, isLoading, min, max, internal }) {
  return (
    <Widget
      icon={faSearchDollar}
      loading={isLoading}
      internal={internal}
      title={
        <span>
          {title}
          <Explain>{tooltip}</Explain>
        </span>
      }
    >
      <NumberRow>
        <ReactCountUp end={min ?? 0} suffix={"€"} separator={"."} />
        <span> - </span>
        <ReactCountUp end={max ?? 0} suffix={"€"} separator={"."} />
      </NumberRow>
    </Widget>
  )
}

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 24px;
`

const NumberRow = styled.div`
  ${widgetNumberCss};
  line-height: 1;
`

export default ProjectValueSection
