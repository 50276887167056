import React, { useEffect, useRef } from "react"
import throttle from "lodash/throttle"
import noUiSlider from "nouislider"
import "nouislider/distribute/nouislider.css"
import { minutes } from "~/utils/dateUtils"
import styled from "@emotion/styled"
import { primaryColor } from "~/utils/style/styleConst"

export interface RangeState {
  from: number
  to: number
}

interface RangeSliderProps {
  min: number
  max: number
  value: RangeState
  onChange: (value: RangeState) => void
}

export function RangeSlider({ min, max, onChange, value }: RangeSliderProps) {
  const $container = useRef<HTMLDivElement>()
  const $slider = useRef<Slider>()
  useEffect(() => {
    const debouncedUpdate = throttle((values: string[]) => {
      onChange({
        from: parseInt(values[0]),
        to: parseInt(values[1]),
      })
    }, 100)
    const slider = noUiSlider.create($container.current, {
      start: [value.from, value.to],
      connect: true,
      tooltips: [dateFormatter, dateFormatter],
      step: 1000,
      range: {
        min,
        max,
      },
      margin: minutes(10),
    })
    $slider.current = slider
    slider.on("slide", debouncedUpdate)
    return () => slider.destroy()
  }, [min, max])

  // useEffect(
  //   debounce(() => {
  //     if (!value) return
  //     $slider.current?.set([value.from, value.to])
  //   }, 500),
  //   [value]
  // )
  return <SliderContainer ref={$container} />
}

const SliderContainer = styled.div`
  .noUi-connect {
    background: ${primaryColor};
  }
`

interface Slider {
  set(values: number[])
}

const dateFormatter = {
  to: (timestamp: number) => {
    return new Date(timestamp).toLocaleString()
  },
}
