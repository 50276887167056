import { useMemo } from "react"
import {
  BrandStats,
  BrandStatsBySize,
  VideoBrandStats,
} from "@core/types/detection/DetectionTypes"

/**
 * sums bySize stats together
 */
export function sumSizeStats(bySize: BrandStatsBySize): BrandStats {
  return Object.entries(bySize).reduce(
    (sum, [sizeName, stats]) => {
      if (sizeName === "all") return sum
      sum.duration += stats.duration
      sum.scale += stats.scale
      sum.score += stats.score
      sum.euroValue += stats.euroValue
      return sum
    },
    {
      duration: 0,
      scale: 0,
      score: 0,
      euroValue: 0,
    } as BrandStats
  )
}

export function useSummedSizeStats(bySize: BrandStatsBySize) {
  return useMemo(() => {
    return bySize && sumSizeStats(bySize)
  }, [bySize])
}

export function useAllSummedSizeStats(
  allBySize: VideoBrandStats
): Record<string, BrandStats> {
  return useMemo(() => {
    return Object.entries(allBySize).reduce((result, [brand, bySize]) => {
      result[brand] = sumSizeStats(bySize)
      return result
    }, {})
  }, [allBySize])
}
