import React from "react"
import { useProjectTopChannels } from "../../../../store/project/projectHooks"
import { PostFilter } from "../../../../@core/types/api/stats/PostFilter"
import WidgetGrid from "../../../../components/widget/WidgetGrid"
import Widget from "../../../../components/widget/Widget"
import { faBullhorn } from "@fortawesome/free-solid-svg-icons"
import TopChannelTable from "./TopChannelTable"

interface TopChannelsProps {
  filter: PostFilter
}

function TopChannels(props: TopChannelsProps) {
  const { isLoading, data } = useProjectTopChannels(props.filter)
  if (!data?.byEngagement?.length) return null
  return (
    <WidgetGrid>
      <Widget
        title={"Most successful channels by reach"}
        icon={faBullhorn}
        loading={isLoading}
      >
        <TopChannelTable channels={data?.byReach} />
      </Widget>
      <Widget
        title={"Most successful channels by engagement"}
        icon={faBullhorn}
        loading={isLoading}
      >
        <TopChannelTable channels={data?.byEngagement} />
      </Widget>
    </WidgetGrid>
  )
}

export default TopChannels
