import React from "react"
import SelectFilter, { FilterOption } from "../active/select/SelectFilter"
import { FilterTagProps } from "../active/FilterTagProps"
import { IdsFilterType } from "../types/IdsFilterType"
import { filterableStatus } from "../../../../@core/types/domain/video/VideoStatus"

interface StatusFilterProps extends FilterTagProps<IdsFilterType> {}

function StatusFilter(props: StatusFilterProps) {
  return <SelectFilter {...props} options={options} />
}

const options: FilterOption[] = filterableStatus.map((platform) => ({
  value: "" + platform.id,
  label: platform.name,
}))

export default StatusFilter
