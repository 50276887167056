import { useState } from "react"
import { CriteriaOptionType } from "~/screens/app/detection/charts/filter/CriteriaOption"

export interface PieChartFilterState {
  category: keyof typeof pieChartCategoryOptions
  criteria: CriteriaOptionType
}

const initialState: PieChartFilterState = {
  category: "Size",
  criteria: CriteriaOptionType.lduration,
}

export function usePieChartFilterState() {
  return useState<PieChartFilterState>(initialState)
}

export const pieChartCategoryOptions = {
  Visibility: "Visibiltiy",
  Size: "Logo Size",
  Age: "Display Time",
  Exclusive: "Exclusivity",
  Contrast: "Contrast",
  Place: "Placement",
  Location: "Section",
  All: "No grouping",
}
