import React from "react"
import SelectFilter, { FilterOption } from "./select/SelectFilter"
import { platforms } from "../../../../@core/types/domain/Platform"
import { FilterTagProps } from "./FilterTagProps"
import { IdsFilterType } from "../types/IdsFilterType"

interface PlatformFilterProps extends FilterTagProps<IdsFilterType> {}

function PlatformFilter(props: PlatformFilterProps) {
  return <SelectFilter {...props} options={options} />
}

const options: FilterOption[] = platforms.map((platform) => ({
  value: "" + platform.id,
  label: platform.name,
}))

export default PlatformFilter
