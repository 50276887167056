import React, { memo } from "react"
import DayPickerInput from "react-day-picker/DayPickerInput"
import "react-day-picker/lib/style.css"
// @ts-ignore
import { ensureDate, formatDate, parseDate } from "../../../utils/dateUtils"
import { dateFormat } from "../../../config"
import moment from "moment"

interface DateInputProps {
  value: Date | string
  onChange: (date: Date) => void
}

function DateInput(props: DateInputProps) {
  const date = ensureDate(props.value)
  return (
    <DayPickerInput
      value={date}
      onDayChange={props.onChange}
      format={dateFormat}
      formatDate={formatDate}
      parseDate={parseDate}
      component={DateInputField}
    />
  )
}

class DateInputField extends React.PureComponent {
  render() {
    return <input type="text" className={"input"} {...this.props} />
  }
}

export default memo(DateInput)
