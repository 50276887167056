import React from "react"
import { Link } from "gatsby"
import { RouteComponentProps } from "@reach/router"
import AppPageLayout from "../../../layouts/app/AppPageLayout"

interface NotFoundProps extends RouteComponentProps {}

function NotFound(props: NotFoundProps) {
  return (
    <AppPageLayout>
      <h1>Not found</h1>
      <p>
        The requested page <code>{props.uri}</code> could not be found.
      </p>
      <p>
        If you are sure that you have followed a valid link, please{" "}
        <Link to={"/app/support"}>get in touch</Link> with us.
      </p>
    </AppPageLayout>
  )
}

export default NotFound
