import React from "react"
import styled from "@emotion/styled"
import { WithChildren } from "~/types/reactTypes"
import MainNav from "../../views/nav/MainNav"
import HeaderWithLogin from "./header/HeaderWithLogin"
import BaseLayout from "../BaseLayout"
import { isMobile } from "~/utils/style/styleUtils"

export interface MainLayoutProps extends WithChildren {}

function MainLayout(props: MainLayoutProps) {
  return (
    <BaseLayout>
      <HeaderWithLogin />
      <MainWrapper className="columns is-fullheight">
        <MainNav />
        <MainSection className={"column section"}>{props.children}</MainSection>
      </MainWrapper>
    </BaseLayout>
  )
}

const MainWrapper = styled.div`
  margin-left: 0;
  margin-right: 0;
`

const MainSection = styled.div`
  ${isMobile} {
    padding-top: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
`

export default MainLayout
