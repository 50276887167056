import React, { memo } from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import classNames from "classnames"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { Stylable, WithChildren } from "~/types/reactTypes"
import { darkTextColor } from "~/layouts/style/styleConst"
import Footer from "~/layouts/main/Footer"
import { navState } from "~/views/nav/navState"
import { isCollapsed } from "~/utils/style/styleUtils"

interface NavMenuProps extends Stylable, WithChildren {}

function NavMenu(props: NavMenuProps) {
  const { toggled } = useRecoilValue(navState)
  return (
    <Container
      className={classNames("column section is-narrow", {
        "is-toggled": toggled,
      })}
    >
      <aside className={"menu"}>{props.children}</aside>
      <Footer />
    </Container>
  )
}

export function Group({ title, children }) {
  return (
    <>
      <p className="menu-label">{title}</p>
      <ul className="menu-list">{children}</ul>
    </>
  )
}

interface ItemProps {
  href: string
  title: string
  icon: IconProp
  comingSoon?: boolean
  internal?: boolean
}

export function Item({ href, title, icon, comingSoon, internal }: ItemProps) {
  const setState = useSetRecoilState(navState) // TODO move up?
  if (comingSoon) {
    return (
      <a className={"is-comingsoon"} title={"Coming soon"}>
        <FontAwesomeIcon fixedWidth icon={icon} />
        <span>{title}</span>
      </a>
    )
  }
  return (
    <Link
      to={href}
      className={internal && "is-internal"}
      activeClassName="is-active"
      onClick={() => {
        setState({ toggled: false })
      }}
    >
      <FontAwesomeIcon fixedWidth icon={icon} />
      <span>{title}</span>
    </Link>
  )
}

const Container = styled.div`
  ${isCollapsed} {
    display: none;
    &.is-toggled {
      display: block;
    }
  }

  background-color: #363636;
  .menu-list a {
    color: ${darkTextColor};
    &:hover {
      background-color: #4b4b4b;
    }
    &.is-comingsoon {
      opacity: 0.3;
      cursor: not-allowed;
      user-select: none;
    }
    &.is-internal {
      color: #ff7575;
    }
    &.is-active {
      background-color: #4b4b4b;
    }
  }
  .menu-label {
    color: #cdcdcd;
  }

  svg {
    margin-right: 8px;
  }

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export default memo(NavMenu)
