import React, { Dispatch, SetStateAction } from "react"
import TextFilter, { Option } from "~/components/filter/TextFilter"
import {
  FilterContainer,
  FilterLabel,
} from "~/screens/app/detection/stats/components/filter/DetectionFilterView"
import {
  SectionStatsFilterState,
  SectionStatsFilterType,
} from "./SectionStatsFilterState"
import styled from "@emotion/styled"

interface SectionStatsFilterProps {
  filter: SectionStatsFilterState
  updateFilter: Dispatch<SetStateAction<SectionStatsFilterState>>
}

export function SectionStatsFilter({
  filter,
  updateFilter,
}: SectionStatsFilterProps) {
  return (
    <Container>
      <FilterContainer>
        <FilterLabel>Filter by target</FilterLabel>
        <TextFilter
          options={targetOptions}
          value={filter.type}
          onChange={(value) => {
            updateFilter((f) => ({
              ...f,
              type: value as SectionStatsFilterType,
            }))
          }}
        />
      </FilterContainer>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 12px;
  text-align: right;
`

const targetOptionConfig: { [key in SectionStatsFilterType]: Option } = {
  [SectionStatsFilterType.size]: {
    value: "size",
    label: "Size",
  },
  [SectionStatsFilterType.place]: {
    value: "place",
    label: "Placement",
  },
}
const targetOptions = Object.values(targetOptionConfig)
