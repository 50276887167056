import React, { ReactNode } from "react"
import styled from "@emotion/styled"
import ProjectSelector, { ProjectSelectionProps } from "./ProjectSelector"
import { isMobile } from "~/utils/style/styleUtils"
import { SelectorLabelWrapper } from "~/elements/misc/SelectorLabelWrapper"

interface FloatingProjectSelectorProps extends ProjectSelectionProps {}

function FloatingProjectSelector(props: FloatingProjectSelectorProps) {
  return (
    <FloatingContainer>
      <ProjectSelectorWithLabel {...props} />
    </FloatingContainer>
  )
}

interface ProjectSelectorWithLabelProps extends ProjectSelectionProps {
  label?: string
  children?: ReactNode
}

export function ProjectSelectorWithLabel({
  label,
  children,
  ...props
}: ProjectSelectorWithLabelProps) {
  return (
    <SelectorLabelWrapper label={label || "Project"}>
      <ProjectSelector {...props} />
      {children}
    </SelectorLabelWrapper>
  )
}

export const FloatingContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  ${isMobile} {
    position: static;
    margin-bottom: 12px;
    text-align: right;
  }
`

export default FloatingProjectSelector
