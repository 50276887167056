export enum VideoCategory {
  // attention: is used as Post category as well
  Unset = 0,
  Platform = 1,
  Athlete = 2,
  Federation = 3,
  Media = 4,
  Private = 5,
  Tv = 6,
  Sponsor = 7,
  NonFootage = 8,
  Others = 9,
}

export interface VideoCategoryType {
  id: number
  name: string
}

const configs: ByVideoCategory<VideoCategoryType> = {
  [VideoCategory.Unset]: {
    id: VideoCategory.Unset,
    name: "Unset",
  },
  [VideoCategory.Platform]: {
    id: VideoCategory.Platform,
    name: "Platform",
  },
  [VideoCategory.Athlete]: {
    id: VideoCategory.Athlete,
    name: "Athlete",
  },
  [VideoCategory.Federation]: {
    id: VideoCategory.Federation,
    name: "Federation",
  },
  [VideoCategory.Media]: {
    id: VideoCategory.Media,
    name: "Media",
  },
  [VideoCategory.Private]: {
    id: VideoCategory.Private,
    name: "Private",
  },
  [VideoCategory.Tv]: {
    id: VideoCategory.Tv,
    name: "Tv",
  },
  [VideoCategory.Sponsor]: {
    id: VideoCategory.Sponsor,
    name: "Sponsor",
  },
  [VideoCategory.NonFootage]: {
    id: VideoCategory.NonFootage,
    name: "Non-Footage",
  },
  [VideoCategory.Others]: {
    id: VideoCategory.Others,
    name: "Others",
  },
}

export type ByVideoCategory<T> = { [key in VideoCategory]: T }

export const allVideoCategories: VideoCategoryType[] = Object.values(configs)

export const videoCategoryById: {
  [s: number]: VideoCategoryType
} = configs
