import { useQuery } from "react-query"
import { ProjectId } from "@core/types/domain/ProjectType"
import { VideoDetectionResult } from "@core/types/domain/video/VideoDetectionType"
import { LivestreamDetectionType } from "@core/types/domain/Livestream"
import { fetchApi, requireOk, toJson } from "~/services/fetchApi"
import { useMemo } from "react"
import { sortStreamResults } from "~/screens/app/detection/stats/stream/sortStreamResults"
import { chunkArray } from "~/utils/js/arrayUtils"

export function fetchVideoDetectionResults(projectId: ProjectId) {
  return fetchApi(`/project/${projectId}/detection`)
    .then(requireOk)
    .then<VideoDetectionResult[]>(toJson)
}

export function useVideoDetectionResults(projectIds: ProjectId[]) {
  if (projectIds?.length > 1)
    throw new Error("currently only a single projectId is supported")
  const projectId = projectIds?.[0]
  return useQuery(
    ["fetchProjectDetections", projectId],
    () => fetchVideoDetectionResults(projectId),
    {
      enabled: !!projectIds?.length,
    }
  )
}

const maxPerRequest = 20

export async function fetchStreamDetectionResults(streamIds: number[]) {
  const chunks = chunkArray(streamIds, maxPerRequest)
  // const results: LivestreamDetectionType[] = [];
  // for (const chunk of chunks) {
  //   results.push(...(await _fetchStreamDetectionResults(chunk)));
  // }
  // return results;
  return Promise.all(chunks.map((chunk) => _fetchStreamDetectionResults(chunk)))
    .then((results) => {
      return [].concat(...results)
    })
    .then((r) => {
      console.debug(r)
      return r
    })
}

function _fetchStreamDetectionResults(streamIds: number[]) {
  return fetchApi(`/livestream/stats?streamIds=${streamIds.join(",")}`)
    .then(requireOk)
    .then<LivestreamDetectionType[]>(toJson)
}

export function useStreamDetectionResults(streamIds: number[]) {
  return useQuery(
    ["useStreamDetectionResults", streamIds],
    () => fetchStreamDetectionResults(streamIds),
    {
      enabled: !!streamIds?.length,
    }
  )
}

export function useStreamDetectionResult(id: number) {
  const ids = useMemo(() => id && [id], [id])
  const { data: results, isFetching } = useStreamDetectionResults(ids)
  return useMemo(() => {
    const sorted = results && sortStreamResults([...results])
    return {
      data: sorted?.[0],
      isFetching,
    }
  }, [results, isFetching])
}
